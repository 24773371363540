import React from 'react'

import { PartnersData } from '../assets/data/Data';
import partnersImage from '../../images/partnersImg.png';
import partnersGroup1 from '../../images/utils/pagesGroup1.svg'
import partnersGroup2 from '../../images/utils/pagesGroup2.svg'

const Partners = () => {
  return (
    <div className="bg-light-white font-sans">   
      <div className="slant-bg relative w-full">
        <div className="w-full flex flex-col md:flex-row md:justify-between px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-24 md:pt-32">
          <div className="w-full md:max-w-60 lg:max-w-80 xl:max-w-[25rem]">
              <h1 className="text-light-white font-bold text-2xl lg:text-4xl">SAED Partner</h1>
              <h3 className="my-4 lg:my-6 text-light-white text-base lg:text-lg font-normal w-full leading-tight">
                As an indigenous Nigerian company with clients across <span className="font-bold">Europe,</span> <span className="font-bold">Canada,</span> and the <span className="font-bold">USA,</span> we pride ourselves on partnering with professionals across multiple fields who possess a diverse range of tech skills. Our partners have helped us build robust applications that cater to a wide variety of businesses and demographics.
              </h3>
          </div>
          <div className="slant-img pt-8 md:pt-0">
              <img src={partnersImage} alt="project" />
          </div>
        </div>
      </div>
			<div className="relative w-full h-full">
          <img className="absolute top-0 right-0" src={partnersGroup1} alt="contact icon" />
          <img className="absolute bottom-0 left-0" src={partnersGroup2} alt="contact icon" />
          <div className="px-8 pt-8 pb-60 max-w-5xl mx-auto">
            <div className="relative w-full grid grid-cols-1 md:grid-cols-2 justify-items-center gap-y-12 xl:gap-y-[3.25rem] gap-x-8 xl:gap-x-[2.625rem]">        
              {
                PartnersData?.map(data => (
                  <div  key={data.name} className="mx-auto w-full max-w-[24rem] xl:max-w-[28rem]">
                    <div
                      className="relative w-full h-[20rem] xl:h-[27.125rem] hover:scale-[1.015] shadow-lg transform transition-transform duration-300 ease-in-out"
                    >
                      <img
                        className="w-full h-full object-cover bg-[#4C4646]"
                        src={data.src}
                        alt={`${data?.name}: ${data?.role}`}
                        onError={(e) => { console.error(`Image failed to load: ${data?.src}`, e); }}
                      />
                      <div className="absolute top-0 right-0 bottom-0 left-0 bg-black/5"></div>
                    </div>
                    <div className="capitalize">
                      <h5 className="mt-4 mb-1 text-base lg:text-lg text-black font-bold">{data?.name}</h5>
                      <p className='text-sm lg:text-base text-[#4C4646] font-semibold'>{data?.role}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
			</div>
		</div>
  )
}

export default Partners;