import React from 'react'

import { FocusData } from '../data/Data'
import FocusList from '../utilities/FocusList'

const focusList = FocusData.map((id, i) => {
    return (
        <FocusList
        key={FocusData[i].id}
        icon={FocusData[i].icon}
        focusHeader={FocusData[i].focusHeader}
        focusText={FocusData[i].focusText} />
    )
})

const Focus = () => {
    return (
        <div className="bg-green-50 w-full h-full">
            <div className="2xl:mx-28 3xl:mx-60 4xl:mx-80 5xl:mx-96">
                <div className="font-sans pt-16 md:pt-20 lg:pt-24 ml-8 md:ml-16 sm:ml-12 md:w-3/4 lg:w-2/5">
                    <span className="text-green-700 font-semibold text-base md:text-lg">What we focus on</span>
                    <h1 className="text-light-black font-bold text-2xl md:text-3xl lg:text-4xl lg:leading-10 my-4">SAED's core focus envelops around</h1>
                </div>
                <div className="ml-8 lg:ml-32 md:ml-16 grid md:grid-cols-2">
                { focusList }
                </div>
            </div>
        </div>
    )
}

export default Focus;