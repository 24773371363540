import React from 'react'

const Indicator = (props) => {
    return (
        <>
            <button className={`mx-1 w-7 border border-light-white pb-0.5 rounded-md focus:outline-none ${props.className}`} onClick={props.clickFunction}></button>
        </>
    )
}

export default Indicator;
