import React from 'react'
import { Link } from 'react-router-dom'

import arrow from '../../../images/icons/arrow.svg'

const MainButton = () => {
    return (
        <Link to="/about">
            <button
                className="main-button capitalize font-semibold text-sm text-light-white tracking-widest transform bg-green-900 hover:bg-green-800 cursor-pointer w-48 py-3 flex justify-center items-center rounded-sm focus:outline-none transition-all duration-300 ease-in-out"
            >
                About Us <img className="ml-3 w-5 transition-all duration-300 ease-in-out" src={arrow} alt="icon" />
            </button>
        </Link>
    )
}

export default MainButton;