import React from 'react'

const NextArrow = (props) => {
    return (
        <button className={`${props.className} flex items-center focus:outline-none`} onClick={props.clickFunction}>
            <span className="text-light-white opacity-95 font-normal text-base">{props.direction}</span>
            <img className="w-4" src={props.src} alt="arrow" />
        </button>
    )
}

export default NextArrow;