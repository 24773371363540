import React from 'react'
import { motion } from 'framer-motion'

const slider = {
  hidden: { scale: 1.15 },
  visible: {
    scale: 1,
    transition: { duration: 0.3 }
  },
  exit: {
    scale: 0.15,
    transition: { duration: 0.3 }
  }
};

const LandingSlider = (props) => {
    return (
      <motion.div
        key={props.url}
        variants={slider}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="landing-bg relative h-screen w-full bg-green-50 bg-center bg-cover bg-no-repeat transition-all ease-in-out"
        style={{ backgroundImage: `url(${props.url})` }}
      >
        <div className="landing-overlay absolute w-full h-screen bg-black bg-opacity-60"></div>
      </motion.div>
    );
  }

export default LandingSlider;
