import React from 'react'

import projectImg from '../../images/projectImg.png'
import projectGroup1 from '../../images/utils/pagesGroup1.svg'
import projectGroup2 from '../../images/utils/pagesGroup2.svg'
import bgLogo from '../../images/SAED_TRANSPARENCY.png'
import projectIcon from '../../images/icons/projectListIcon.svg'

import { ProjectData } from '../assets/data/Data'

const Projects = () => {
    return (
        <div className="bg-light-white font-sans">
            <div className="slant-bg w-full flex flex-col md:flex-row md:justify-between px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-24 md:pt-32">
                <div className="w-full md:max-w-60 lg:max-w-80 xl:max-w-[25rem]">
                    <h1 className="text-light-white font-bold text-2xl lg:text-4xl">Projects</h1>
                    <h3 className="my-4 lg:my-6 text-light-white text-base lg:text-lg font-normal w-full leading-tight">We enable clients focus on their core business(es) whilst outsourcing their technology needs to us. Feel free to contact us for your projects. We will get back to you as soon as we can.</h3>
                </div>
                <div className="slant-img pt-8 md:pt-0">
                    <img src={projectImg} alt="project" />
                </div>
            </div>
            <div className="relative w-full h-full bg-no-repeat bg-center bg-logo" style={{ backgroundImage: `url(${bgLogo})` }}>
                <img className="absolute top-0 right-0" src={projectGroup1} alt="contact icon" />
                <img className="absolute bottom-0 left-0" src={projectGroup2} alt="contact icon" />
                <div className="px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 grid pb-60 md:grid-cols-2">
                        {ProjectData.map(user => (
                            <div key={user.id} className="flex flex-col py-8 z-10">
                                <div className="flex items-center h-24">
                                    <img className="w-24 h-auto" src={user.image} alt="client logo" />
                                    {/* <span className="text-light-black font-bold text-lg lg:text-xl ml-4">{user.client}</span> */}
                                </div>
                                <ul className="pt-1 text-light-grey font-normal text-sm lg:text-base w-full sm:w-11/12 lg:w-4/5 2xl:w-11/12 leading-tight">
                                    <li className="flex leading-loose"><img className="w-6 h-6 mr-2" src={projectIcon} alt="project icon" />{user.projectOne}</li>
                                    <li className="flex mt-2 leading-loose"><img className="w-6 h-6 mr-2" src={projectIcon} alt="project icon" />{user.projectTwo}</li>
                                </ul>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default Projects;
