import React, { forwardRef } from 'react'

import MainButton from '../utilities/MainButton'
import heroVector from '../../../images/utils/hero-vector.svg'

const Hero = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="absolute mt-4 md:mt-8 md:ml-16 sm:ml-12 ml-8 top-1/2 transform -translate-y-1/2 text-light-white w-4/5 md:w-1/2 xl:w-2/5 2xl:w-1/3">
            <h1 className="sans font-bold text-3xl md:text-4xl tracking-wide mb-2">{ props.header }</h1>
            <img className="hero-img" src={heroVector} alt="icon" />
            <p className="sans font-normal text-base lg:w-4/5 my-8 leading-tight">{ props.text }</p>
            <MainButton />
        </div>
    )
})

export default Hero;