import React, { useState } from 'react'
import { motion } from 'framer-motion'

import bgStrokes from '../../../images/utils/bgStrokes.svg'

import { WorkData, WorkDataMobile } from '../data/Data'
import PrevArrow from '../utilities/PrevArrow'
import NextArrow from '../utilities/NextArrow'

import arrow from '../../../images/icons/arrow.svg'

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: { opacity: 1, scale: 1,
    transition: { delayChildren: 1.5, staggerChildren: 0.3 }
    }
};

const item = {
    hidden: { y: -20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
};

const Work = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    // const prev = () => {
    //     const lastIndex = WorkData.length - 1;
    //     const shouldResetIndex = activeIndex === 0;
    //     const index =  shouldResetIndex ? lastIndex : activeIndex - 1;
    
    //     setActiveIndex(index);
    // }
    
    // const next = () => {
    //     const lastIndex = WorkData.length - 1;
    //     const shouldResetIndex = activeIndex === lastIndex;
    //     const index =  shouldResetIndex ? 0 : activeIndex + 1;
    
    //     setActiveIndex(index);
    //   }

    return (
        <div className="bg-green-50 w-full h-full bg-no-repeat bg-center lg:bg-bottom font-sans" style={{ backgroundImage: `url(${bgStrokes})` }}>
            <div className="relative 2xl:mx-28 3xl:mx-60 4xl:mx-80 5xl:mx-96">
                <div className="pt-16 md:pt-20 lg:pt-24 ml-8 md:ml-16 sm:ml-12 md:w-3/4 lg:w-2/5">
                    <span className="text-green-700 font-semibold lo text-base md:text-lg">How we work</span>
                    <h1 className="text-light-black font-bold text-2xl md:text-3xl lg:text-4xl lg:leading-10 my-4">SAED's process to building top-notch products</h1>
                </div>
                <motion.div className="w-5/6 lg:w-11/12 mx-auto mt-12 pb-20 lg:mb-0 flex items-center flex-row lg:justify-around overflow-x-scroll lg:overflow-x-hidden" variants={container} initial="hidden" animate="visible">
                    {/* <PrevArrow className="absolute left-2 xl:left-10 z-10 hidden lg:flex bg-green-700 p-2 rounded-full" src={arrow} clickFunction={prev} />
                    <NextArrow className="absolute right-2 xl:right-10 z-10 hidden lg:flex bg-green-700 p-2 rounded-full" src={arrow} clickFunction={next} /> */}
               
                    <PrevArrow className="absolute left-2 xl:left-10 z-10 block lg:hidden bg-green-700 p-2 rounded-full" src={arrow} />
                    <NextArrow className="absolute right-2 xl:right-10 z-10 block lg:hidden bg-green-700 p-2 rounded-full" src={arrow} />

                    {WorkData[activeIndex].map((user) => {
                        return (
                            <motion.div key={user.id} className="md:w-72 xl:w-80 h-72 lg:mx-4 xl:mx-8 hidden lg:flex flex-col items-center justify-center text-center mb-0" variants={item}>
                                <img className="h-16" src={user.icon} alt="work icon" />
                                <h3 className="md:w-72 xl:w-80 text-light-black font-medium text-2xl uppercase pt-12">{user.workHeader}</h3>
                                <p className="md:w-72 xl:w-80 text-light-grey font-normal text-base pt-6 leading-relaxed">{user.workText}</p>
                            </motion.div>
                        )
                    })}
                
                    {/* MOBILE VIEW < 1024px */}
                    {WorkDataMobile.map((user) => {
                        return (
                            <motion.div key={user.id} className="w-3/4 sm:w-64 md:w-72 h-auto mx-8 flex lg:hidden flex-col items-center text-center mb-12" variants={item}>
                                <img className="h-16" src={user.icon} alt="work icon" />
                                <h3 className="w-60 sm:w-64 md:w-72 text-light-black font-medium text-2xl uppercase pt-4">{user.workHeader}</h3>
                                <p className="w-60 sm:w-64 md:w-72 text-light-grey font-normal text-base pt-4 leading-relaxed">{user.workText}</p>
                            </motion.div>
                        )
                    })}
                </motion.div>
                <div className="hidden lg:flex justify-center items-center pb-20">
                    {WorkData.map((_, card) => {
                        return ( 
                            <div key={card} className={`w-4 h-4 mx-2 border border-green-700 cursor-pointer ${activeIndex === card ? "bg-green-700" : "bg-transparent"}`} onClick={() => setActiveIndex(card)}></div>
                    )})}
                </div>
            </div>
        </div>
    )
}

export default Work;
