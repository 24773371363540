import React from "react";
import { useForm } from "react-hook-form"
import { motion } from "framer-motion"

import address1 from "../../images/icons/addressIcon1.svg";
import address2 from "../../images/icons/addressIcon2.svg";
import phone from "../../images/icons/phone.svg";
import contactGroup1 from "../../images/utils/contactGroup1.svg";
import contactGroup2 from "../../images/utils/contactGroup2.svg";

const contactHead = {
    hidden: { y:-20, opacity: 0 },
    visible: { y:0, opacity: 1,
    transition: { delay: 0.5 }
    }
};

const formContainer = {
    hidden: { opacity: 1, scale: 0 },
    visible: { opacity: 1, scale: 1,
    transition: { delayChildren: 1, staggerChildren: 0.2 }
    }
};

const formItems = {
    hidden: { x: -20, opacity: 0 },
    visible: { x: 0, opacity: 1 }
};

const items = {
    hidden: { x: -15, opacity: 0 },
    visible: { x: 0, opacity: 1, 
        transition: { delay: 0.3 } }
};

const card = {
    hidden: { y:-20, opacity: 0 },
    visible: { y:0, opacity: 1,
    transition: { delay: 1 }
    }
};

const Contact = () => {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	const onSubmit = data => {
		console.log(data)
		reset()
	}

	return (
		<div className="bg-light-white font-sans">
			<div className="py-24 md:py-32 px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112">
				<motion.h1 className="text-green-700 text-4xl font-bold" variants={contactHead} initial="hidden" animate="visible">Contact Us</motion.h1>
				<div className="flex flex-col xl:flex-row xl:justify-between">
					<div>
						<motion.h3 className="my-4 lg:my-6 text-light-grey text-base lg:text-lg font-normal w-full md:w-3/4 lg:w-2/3 xl:w-3/4 leading-tight" variants={contactHead} initial="hidden" animate="visible">
							Feel free to contact us any time. We will get back to you as soon
							as we can
						</motion.h3>
						<motion.form className="flex flex-col" onSubmit={handleSubmit(onSubmit)} variants={formContainer} initial="hidden" animate="visible">
							<motion.input variants={formItems}
								className="p-3 my-4 rounded-md border text-sm font-light text-light-grey border-form-grey w-full md:w-3/4 lg:w-2/3 xl:w-3/4 focus:outline-none"
								placeholder="Name"
								type="text"
								{...register("name", { required: true })}
							/>
							{errors.name && (<motion.span className="bg-red-100 flex items-center p-3 -mt-2 w-full md:w-3/4 lg:w-2/3 xl:w-3/4 text-red-600 text-sm font-medium" variants={items} initial="hidden" animate="visible">Name is required</motion.span>)}
							<motion.input variants={formItems}
								className="p-3 my-4 rounded-md border text-sm font-light text-light-grey border-form-grey w-full md:w-3/4 lg:w-2/3 xl:w-3/4 focus:outline-none"
								placeholder="Email"
								type="email"
								{...register("email", { required: true, pattern: /^\S+@\S+$/i })}
							/>
							{errors.email && (<motion.span className="bg-red-100 flex items-center p-3 -mt-2 w-full md:w-3/4 lg:w-2/3 xl:w-3/4 text-red-600 text-sm font-medium" variants={items} initial="hidden" animate="visible">Email is required</motion.span>)}
							<motion.textarea variants={formItems}
								className="px-3 pt-3 pb-16 my-4 rounded-md border text-sm font-light text-light-grey border-form-grey w-full md:w-3/4 lg:w-2/3 xl:w-3/4 focus:outline-none"
								placeholder="Message"
								{...register("message", { required: true })}
							/>
							{errors.message && (<motion.span className="bg-red-100 flex items-center p-3 -mt-2 w-full md:w-3/4 lg:w-2/3 xl:w-3/4 text-red-600 text-sm font-medium" variants={items} initial="hidden" animate="visible">Message is required</motion.span>)}
							<motion.button variants={formItems}
								className="bg-green-700 py-3 my-4 rounded-md text-sm font-bold text-light-white w-full md:w-3/4 lg:w-2/3 xl:w-3/4 focus:outline-none hover:bg-green-800"
								type="submit"
							>
								Send message
							</motion.button>
						</motion.form>
					</div>
					<motion.div className="contact-box relative mt-16 xl:mt-0 w-full md:w-3/4 lg:w-2/3 xl:w-100 h-full border-0 border-light-white rounded-xl" variants={card} initial="hidden" animate="visible">
						<img
							className="absolute top-0 right-0 overflow-hidden rounded-xl"
							src={contactGroup1}
							alt="contact icon"
						/>
						<img
							className="absolute bottom-0 left-0 overflow-hidden rounded-xl"
							src={contactGroup2}
							alt="contact icon"
						/>
						<div className="pt-8 pb-10 pl-4 sm:pl-8">
							<div className="mb-8">
								<span className="text-light-black text-base font-medium flex items-center">
									<img
										className="w-6 h-6 mr-4"
										src={address1}
										alt="address icon"
									/>
									Head Office Surulere (Lagos)
								</span>
								<p className="w-3/4 my-2 ml-10 text-light-grey text-base font-normal leading-tight">
									60 Bode Thomas Road, Surulere, Lagos, Nigeria.
								</p>
								<div className="flex items-center text-light-grey text-sm font-normal">
									<img className="ml-10 mr-4" src={phone} alt="phone icon" />
									<ul>
										<li>08113290168</li>
										<li>08113290176</li>
										<li>08113290168</li>
									</ul>
								</div>
							</div>
							<div>
								<span className="text-light-black text-base font-medium flex items-center">
									<img
										className="w-6 h-6 mr-4"
										src={address2}
										alt="address icon"
									/>
									Ikeja Office (Lagos)
								</span>
								<p className="w-3/4 my-2 ml-10 text-light-grey text-base font-normal leading-tight">
									30 Afolabi Aina Street, Ikeja, Lagos, Nigeria.
								</p>
								<div className="flex items-center text-light-grey text-sm font-normal">
									<img className="ml-10 mr-4" src={phone} alt="phone icon" />
									<ul>
										<li>08113290168</li>
										<li>08113290176</li>
										<li>08113290168</li>
									</ul>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
