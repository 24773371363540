import React from 'react'

const PrevArrow = (props) => {
    return (
        <button className={`${props.className} flex items-center focus:outline-none`} onClick={props.clickFunction}>
            <img className="w-4 transform rotate-180" src={props.src} alt="arrow" />
            <span className="text-light-white opacity-95 font-normal text-base">{props.direction}</span>
        </button>
    )
}

export default PrevArrow;
