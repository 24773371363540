import React from 'react';

const NotFound = () => {
  return (
    <div className="pt-56 pb-28 px-2 flex flex-col items-center justify-center">
      <h1 className="text-8xl font-bold text-light-grey">404</h1>
      <p className="text-lg text-light-grey">Page Not Found</p>
      <a href="/" className="mt-4 font-medium text-sm lg:text-base text-light-white tracking-widest bg-green-900 hover:bg-green-800 hover:-translate-y-0.5 cursor-pointer w-48 py-3 flex justify-center items-center rounded-sm transition-all duration-300 ease-in-out">Go Home</a>
    </div>
  );
};

export default NotFound;
