import React, { useState } from 'react'
import { motion } from 'framer-motion'

import { BusinessData, BusinessDataMobile } from '../data/Data'
import PrevArrow from '../utilities/PrevArrow'
import NextArrow from '../utilities/NextArrow'

import slantTop from '../../../images/utils/slantTop.svg'
import goldGroup from '../../../images/utils/goldGroup.svg'
import arrowGreen from '../../../images/icons/arrowGreen.svg'
  
const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: { opacity: 1, scale: 1,
    transition: { delayChildren: 1.5, staggerChildren: 0.3 }
    }
};

const item = {
    hidden: { y: -20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
};

const BusinessLine = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    // const prev = () => {
    //     const lastIndex = BusinessData.length - 1;
    //     const shouldResetIndex = activeIndex === 0;
    //     const index =  shouldResetIndex ? lastIndex : activeIndex - 1;
    
    //     setActiveIndex(index);
    // }
    
    // const next = () => {
    //     const lastIndex = BusinessData.length - 1;
    //     const shouldResetIndex = activeIndex === lastIndex;
    //     const index =  shouldResetIndex ? 0 : activeIndex + 1;
    
    //     setActiveIndex(index);
    //   }

    return (
        <div className="font-sans bg-green-50 h-full w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${slantTop})` }}>
            <div className="relative 2xl:mx-28 3xl:mx-60 4xl:mx-80 5xl:mx-96">
                <img className="absolute h-60 md:h-72 lg:h-auto mt-24 md:mt-16 lg:mt-12 right-0" src={goldGroup} alt="business" />
                <div className="pt-32 md:pt-36 lg:pt-52 ml-8 md:ml-16 sm:ml-12 md:w-3/4 lg:w-2/5">
                    <span className="text-yellow-500 font-semibold text-base lg:text-lg">Our business line</span>
                    <h1 className="text-white font-bold text-2xl lg:text-4xl lg:leading-10 my-4">SAED's current operation involves</h1>
                </div>
                <motion.div className="w-5/6 lg:w-11/12 mt-12 pb-20 lg:mb-0 mx-auto flex items-center lg:justify-around overflow-x-scroll lg:overflow-x-hidden" variants={container} initial="hidden" animate="visible">

                    {/* <PrevArrow className="absolute left-2 xl:left-10 z-10 hidden lg:flex bg-light-white p-2 rounded-full" src={arrowGreen} clickFunction={prev} />
                    <NextArrow className="absolute right-2 xl:right-10 z-10 hidden lg:flex bg-light-white p-2 rounded-full" src={arrowGreen} clickFunction={next} /> */}
               
                    <PrevArrow className="absolute left-2 xl:left-10 z-10 block lg:hidden bg-light-white p-2 rounded-full" src={arrowGreen} />
                    <NextArrow className="absolute right-2 xl:right-10 z-10 block lg:hidden bg-light-white p-2 rounded-full" src={arrowGreen} />

                    {BusinessData[activeIndex].map(user => (
                        <motion.div key={user.id} className="mx-8 lg:mx-4 xl:mx-8 hidden lg:block" variants={item}>
                            <div className="relative w-60 sm:w-64 md:w-72 xl:w-80 h-48 rounded-t-2xl">
                                <div className="absolute z-10 h-full w-full bg-black bg-opacity-20 rounded-t-2xl"></div>
                                <img className="w-60 sm:w-64 md:w-72 xl:w-80 h-48 rounded-t-2xl" src={user.image} alt="business line" />
                            </div>
                            <div className="relative w-60 sm:w-64 md:w-72 xl:w-80 h-48 p-4 flex flex-col justify-items-center font-base break-words bg-light-white rounded-b-2xl">
                                <h3 className="text-light-grey font-bold">{user.businessHeader}</h3>
                                <p className="text-light-grey my-4 lg:my-6 leading-tight">{user.businessText}</p>
                                <button className="absolute bottom-4 flex items-center text-sm font-bold text-green-700 focus:outline-none">Learn more</button>
                            </div>
                        </motion.div>
                    ))}

                    {/* MOBILE VIEW < 1024px */}
                    {BusinessDataMobile.map(user => (
                        <motion.div key={user.id} className="mx-8 lg:mx-4 xl:mx-8 block lg:hidden" variants={item}>
                            <div className="relative w-60 sm:w-64 md:w-72 xl:w-80 h-48 rounded-t-2xl">
                                <div className="absolute z-10 h-full w-full bg-black bg-opacity-20 rounded-t-2xl"></div>
                                <img className="w-60 sm:w-64 md:w-72 xl:w-80 h-48 rounded-t-2xl" src={user.image} alt="business line" />
                            </div>
                            <div className="relative w-60 sm:w-64 md:w-72 xl:w-80 h-48 p-4 flex flex-col justify-items-center font-base break-words bg-light-white rounded-b-2xl">
                                <h3 className="text-light-grey font-bold">{user.businessHeader}</h3>
                                <p className="text-light-grey my-4 lg:my-6 leading-tight">{user.businessText}</p>
                                <button className="absolute bottom-4 flex items-center text-sm font-bold text-green-700 focus:outline-none">Learn more</button>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                <div className="hidden lg:flex justify-center items-center pb-24">
                    {BusinessData.map((_, card) => {
                        return ( 
                            <div key={card} className={`w-4 h-4 mx-2 border border-light-white cursor-pointer ${activeIndex === card ? "bg-light-white" : "bg-transparent"}`} onClick={() => setActiveIndex(card)}></div>
                    )})}
                </div>
            </div>
        </div>
    )
}

export default BusinessLine;
