import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Navbar from './components/assets/sections/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Projects from './components/pages/Projects';
import Partners from './components/pages/Partners';
import Careers from './components/pages/Careers';
import Contact from './components/pages/Contact';
import Footer from './components/assets/sections/Footer';
import NotFound from './components/pages/NotFound';
import ScrollToTop from './components/assets/utilities/ScrollToTop';

const App = () => {
	return (
		<Router>
		<ScrollToTop />
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/partners" element={<Partners />} />
				<Route path="/careers" element={<Careers />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
