import React from 'react'

import logo from '../../../images/saed-logo.png'
import mail from '../../../images/icons/mail.svg'
import twitter from '../../../images/icons/twitter.svg'
import instagram from '../../../images/icons/instagram.svg'

const Footer = () => {
    return (
        <div className="bg-green-100 text-light-grey py-20 font-sans">
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-around mx-auto w-5/6 lg:w-11/12 xl:w-3/4 2xl:w-3/5 3xl:w-1/2">
                <div>
                    <img className="mb-8 md:mb-0" src={logo} alt="SAED logo" />
                </div>
                <div className="flex flex-col items-start md:items-center">
                    <span className="text-lg font-bold">Social</span>
                    <div className="text-base font-medium flex items-start md:items-center py-4">
                        <a href="mailto: care@saedintservices.com" target="_blank" rel="noreferrer">
                        <div className="flex items-center">
                            <img className="w-5 h-4 lg:ml-12 mr-4 md:mr-3" src={mail} alt="mail icon" />
                            <span className="hidden lg:block">care@saedintservices.com</span>
                        </div>
                        </a>
                        <a href="https://twitter.com/saed_int" target="_blank" rel="noreferrer">
                        <div className="flex items-center">
                            <img className="w-5 h-4 lg:ml-12 mr-4 md:mr-3" src={twitter} alt="twitter icon" />
                            <span className="hidden lg:block">@SAED_int</span>
                        </div>
                        </a>
                        <a href="https://www.instagram.com/saed_int" target="_blank" rel="noreferrer">
                        <div className="flex items-center">
                            <img className="w-5 h-4 lg:ml-12 mr-4 md:mr-3" src={instagram} alt="instagram icon" />
                            <span className="hidden lg:block">@SAED_int</span>
                        </div>
                        </a>
                    </div>
                </div>
                <div className="flex flex-col items-start md:items-center">
                    <span className="text-lg font-bold">Privacy</span>
                    <span className="text-base font-medium py-4">Privacy policy</span>
                </div>
            </div>
            <div className="text-sm font-normal ml-8 sm:ml-12 md:mx-auto md:text-center pt-8">©2020, SAED. All Rights Reserved.</div>
        </div>
    )
}

export default Footer;
