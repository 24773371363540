import React from "react";

import careerImg from '../../images/careerImg.png'
import bgStrokes from '../../images/utils/bgStrokes.svg'
import careerGrowth from '../../images/icons/growth.svg'
import Package from '../../images/icons/package.svg'
import workHours from '../../images/icons/workHours.svg'
import colleagues from '../../images/icons/greatColleagues.svg'
import mail from '../../images/icons/mail.svg'

const Careers = () => {
  	return (
		<div className="bg-light-white font-sans">
			<div className="slant-bg w-full flex flex-col md:flex-row md:justify-between px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-24 md:pt-32">
				<div className="w-full md:max-w-60 lg:max-w-80 xl:max-w-[25rem]">
					<h1 className="text-light-white font-bold text-2xl lg:text-4xl">Join our team</h1>
					<h3 className="my-4 lg:my-6 text-light-white text-base lg:text-lg font-normal w-full leading-tight">A company deeply committed to integrity and responsibility, dares to dream the impossible and then goes on to achieve it.</h3>
				</div>
				<div className="slant-img pt-8 md:pt-0">
					<img src={careerImg} alt="project" />
				</div>
			</div>
			<div className="w-full h-full bg-no-repeat bg-center" style={{ backgroundImage: `url(${bgStrokes})` }}>
				<div className="px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-8 pb-60">
					<h3 className="text-light-black text-2xl lg:text-4xl font-bold">When you join us</h3>
					<div className="grid md:grid-cols-2 gap-y-16 py-14">
						<div className="flex items-center w-full sm:w-11/12 lg:w-full">
							<img className="w-12 h-12" src={careerGrowth} alt="career icon" />
							<span className="text-light-black font-bold text-base lg:text-lg ml-4">Career growth and opportunity</span>
						</div>
						<div className="flex items-center w-full sm:w-11/12 lg:w-full">
							<img className="w-12 h-12" src={Package} alt="career icon" />
							<span className="text-light-black font-bold text-base lg:text-lg ml-4">Competitive remuneration package</span>
						</div>
						<div className="flex items-center w-full sm:w-11/12 lg:w-full">
							<img className="w-12 h-12" src={workHours} alt="career icon" />
							<span className="text-light-black font-bold text-base lg:text-lg ml-4">Flexible work hours</span>
						</div>
						<div className="flex items-center w-full sm:w-11/12 lg:w-full">
							<img className="w-12 h-12" src={colleagues} alt="career icon" />
							<span className="text-light-black font-bold text-base lg:text-lg ml-4">Great colleagues</span>
						</div>
					</div>
					<div className="py-10 flex flex-col">
						<span className="text-green-700 font-semibold text-base lg:text-lg pb-2">Join SAED</span>
						<span className="text-light-grey font-bold text-lg lg:text-2xl">Excited about a role ? Apply by sending us a mail</span>
					</div>
					<div className="flex flex-col md:flex-row md:items-center">
						<a href="mailto: careers@saed.com" target="_blank" rel="noreferrer">
						<div className="flex flex-row items-center">
							<img className="w-5 h-4" src={mail} alt="career mail" />
							<span className="ml-4 text-light-black font-bold text-sm lg:text-base">careers@saed.com</span>
						</div>
						</a>
						<a href="mailto: care@saedintservices.com" target="_blank" rel="noreferrer">
						<div className="flex flex-row items-center mt-4 md:mt-0 ml-0 md:ml-10 lg:ml-14">
							<img className="w-5 h-4" src={mail} alt="admin mail" />
							<span className="ml-4 text-light-black font-bold text-sm lg:text-base">care@saedintservices.com</span>
						</div>
						</a>
					</div>
				</div>
			</div>
		</div>
  	)
}

export default Careers;
