import React, { useState, useEffect, useCallback, useRef } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { motion } from 'framer-motion'

import { Data } from '../data/Data'
import LandingSlider from '../utilities/LandingSlider'
import Hero from './Hero'
import PrevArrow from '../utilities/PrevArrow'
import NextArrow from '../utilities/NextArrow'
import Indicator from '../utilities/Indicator'

import arrow from '../../../images/icons/arrow.svg'
  
const items = {
    hidden: { y: -10, opacity: 0 },
    visible: { y: 0, opacity: 1, 
        transition: { delay: 0.5 } }
};

const duration = 150;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }
  
  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };
  

const Landing = ({ in: inProp }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const heroRef = useRef(null);

    const ImagePreloader = ({ src, onLoad }) => {
        useEffect(() => {
            const img = new Image();
            img.src = src;
            img.onload = onLoad;
        }, [src, onLoad]);
        
        return null;
    };

    const prevSlide = () => {
        const lastIndex = Data.length - 1;
        const shouldResetIndex = activeIndex === 0;
        const index =  shouldResetIndex ? lastIndex : activeIndex - 1;
    
        setActiveIndex(index);
    }
    
    const nextSlide = useCallback(() => {
        const lastIndex = Data.length - 1;
        const shouldResetIndex = activeIndex === lastIndex;
        const index =  shouldResetIndex ? 0 : activeIndex + 1;
    
        setActiveIndex(index);
      }, [activeIndex])

    useEffect(() => {
        const interval = setInterval(nextSlide, 15000)

        return () => {
            clearInterval(interval)
        }
    }, [nextSlide])

    const indicator = Data.map((_, index) => {
        return (
            <Indicator key={Data[index].id} className={`${activeIndex === index ? "bg-light-white" : "bg-transparent"}`} clickFunction={() => setActiveIndex(index)} />
        );
    })

    const { image, heroHeader, heroText } = Data[activeIndex];

    return (
        <div className="relative font-sans">
            <ImagePreloader src={image} />
            <LandingSlider key={activeIndex} url={image} />
            <div className="2xl:ml-28 3xl:ml-60 4xl:ml-80 5xl:ml-96">
                <TransitionGroup>
                    <CSSTransition classNames="hero" in={inProp} timeout={{ enter: duration, exit: 0 }} key={activeIndex} nodeRef={heroRef}>
                        {state => (
                            <Hero
                                header={heroHeader}
                                text={heroText}
                                ref={heroRef}
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state]
                                }}
                            />
                        )}
                    </CSSTransition>
                </TransitionGroup>
                <motion.div className="indicator absolute flex-col bottom-8 md:bottom-12 md:ml-16 sm:ml-12 ml-8 w-48" variants={items} initial="hidden" animate="visible">
                    <div className="flex justify-around items-center mx-4 my-3 2xl:my-6">
                        {indicator}
                    </div>
                    <div className="flex justify-between items-center">
                        <PrevArrow src={arrow} direction="Prev" clickFunction={prevSlide} />
                        <NextArrow src={arrow} direction="Next" clickFunction={nextSlide} />
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Landing;