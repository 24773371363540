import React from 'react';

import bgLogo from '../../images/SAED_TRANSPARENCY.png'
import aboutImg from '../../images/aboutImg.png';
import aboutGroup1 from '../../images/utils/pagesGroup1.svg'
import aboutGroup2 from '../../images/utils/pagesGroup2.svg'

const About = () => {
	return (
		<div className="bg-light-white font-sans">
            
            <div className="slant-bg relative w-full">
                <div className="w-full flex flex-col md:flex-row md:justify-between px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-24 md:pt-32">
                    <div className="w-full md:max-w-60 lg:max-w-80 xl:max-w-[25rem]">
                        <h1 className="text-light-white font-bold text-2xl lg:text-4xl">SAED As A Company</h1>
                        <h3 className="my-4 lg:my-6 text-light-white text-base lg:text-lg font-normal w-full leading-tight">SAED integrated services is a leading technology strategy & consulting, software development and testing company.</h3>
                    </div>
                <div className="slant-img pt-8 md:pt-0">
                    <img src={aboutImg} alt="project" />
                </div>
                </div>
            </div>
			<div className="relative w-full h-full bg-no-repeat bg-center bg-logo" style={{ backgroundImage: `url(${bgLogo})` }}>
                <img className="absolute top-0 right-0" src={aboutGroup1} alt="contact icon" />
                <img className="absolute bottom-0 left-0" src={aboutGroup2} alt="contact icon" />
                <div className="px-8 sm:px-12 md:px-16 2xl:px-44 3xl:px-76 4xl:px-96 5xl:px-112 pt-8 pb-60">
					<div>        
						<p className="pb-8 leading-loose">SAED INTEGRATED SERVICES is a technology company focused on building SaaS platforms to improve the Nigerian ecosystem. A technology consultancy, advisory & development company focused on delivering enterprise services across various industries to ensure the continuous improvement of the Nigerian and West African Eco-system..</p>
						<p className="pb-8 leading-loose">Comprised of several experts across all Information Technology related fields, we and our partners, Netow Solutions(a leading software testing company) strive to use technological advancement to provide affordable services to end-users who ordinarily would not be able to afford paying a premium for these services.</p>
						<p className=" leading-loose">Our mission is to help our clients achieve growth by eliminating their pain points with ground breaking, yet simple solutions to deliver the best possible designs and implementation to suit their specific needs.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About;
