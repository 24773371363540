import React from 'react'

const FocusList = (props) => {
    return (
        <div className="my-4 font-sans text-base flex flex-col items-start">
            <img className="w-20 h-20 -ml-4" src={props.icon} alt="icon info" />
            <h3 className="font-semibold text-light-black">{props.focusHeader}</h3>
            <p className="w-11/12 lg:w-4/5 my-4 text-light-grey leading-tight">{props.focusText}</p>
        </div>
    )
}

export default FocusList;
