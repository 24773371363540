import React from 'react'

import Landing from '../assets/sections/Landing'
import Focus from '../assets/sections/Focus'
import BusinessLine from '../assets/sections/BusinessLine'
import Work from '../assets/sections/Work'

const Home = () => {
    return (
        <div>
            <Landing />
            <Focus />
            <BusinessLine />
            <Work />
        </div>
    )
}

export default Home;