import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import logo from '../../../images/saed-logo.png'
import navActive from '../../../images/icons/navActive.svg'
import navCancel from '../../../images/icons/navCancel.svg'
import navActiveContact from '../../../images/icons/navActiveContact.svg'

const logoIcon = {
    hidden: { y:-20, opacity: 0 },
    visible: { y:0, opacity: 1,
    transition: { delay: 0.5 }
    }
};

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: { opacity: 1, scale: 1,
    transition: { delayChildren: 0.75, staggerChildren: 0.15 }
    }
};

const item = {
    hidden: { y: -20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
};

const lightLinkPaths = ["/", "/about", "/projects", "/partners", "/careers", "contact"]

const Navbar = () => {
    const [mobileNav, setMobileNav] = useState(false)
    const [navBg, setNavBg] = useState(false)
    

    const NavToggle = () => {
        setMobileNav(!mobileNav);
    }

    let location = useLocation();

    useEffect(() => {
    const handleScroll = () => {
        window.scrollY > 10 ? setNavBg(true) : setNavBg(false)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
        window.removeEventListener("scroll", () => handleScroll)
    }
    }, [])

    return (
        <header className={`w-full lg:py-3 fixed top-0 z-50 ${navBg ? "bg-[#065f46] opacity-95" : "bg-transparent"}`}>
            <div className="h-20 lg:h-16 my-0 lg:mx-16 flex justify-between lg:items-center">
                <motion.div className="py-3 lg:py-0 h-12 ml-8 sm:ml-12 md:ml-16 2xl:ml-28 3xl:ml-60 4xl:ml-80 5xl:ml-96 lg:ml-0" variants={logoIcon} initial="hidden" animate="visible">
                    <NavLink to="/"><img src={logo} alt="SAED logo" /></NavLink>
                </motion.div>
                <div>
                    <nav
                        className={`
                            font-sans text-sm h-screen lg:h-auto ${mobileNav ? "bg-[#065f46] opacity-95" : "bg-transparent"} lg:bg-transparent ${(lightLinkPaths.includes(location.pathname) && (location.pathname !== 'contact')) || navBg ? "lg:text-light-white" : "lg:text-light-grey"} text-light-white font-bold transition-colors ease-in-out duration-150
                        `}
                    >
                        <motion.div className="pt-8 lg:hidden cursor-pointer ml-4 mr-8 sm:mr-12 md:mr-16 lg:mx-0" onClick={NavToggle} variants={logoIcon} initial="hidden" animate="visible">
                            {mobileNav ? <img src={navCancel} alt="nav icon" /> : <img src={`${(lightLinkPaths.includes(location.pathname) && (location.pathname !== 'contact')) || navBg ? navActive : navActiveContact}`} alt="nav icon" />}
                        </motion.div>
                        <motion.ul className={`nav-right flex-col ${mobileNav ? "mt-4 flex" : "hidden"} lg:flex lg:flex-row lg:items-center ml-4 mr-8 sm:mr-12 md:mr-16 2xl:mr-28 3xl:mr-60 4xl:mr-80 5xl:mr-96 lg:mx-0`} variants={container} initial="hidden" animate="visible">
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/">
                                    Home
                                </NavLink>
                            </motion.li>
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/about">
                                    About Us
                                </NavLink>
                            </motion.li>
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/projects">
                                    Projects
                                </NavLink>
                            </motion.li>
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/partners">
                                    Partners
                                </NavLink>
                            </motion.li>
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/careers">
                                    Careers
                                </NavLink>
                            </motion.li>
                            <motion.li className="my-6" variants={item}>
                                <NavLink className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`} to="/contact">
                                    Contact Us
                                </NavLink>
                            </motion.li>
                        </motion.ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Navbar;
