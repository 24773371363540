import image0 from '../../../images/artificial-intelligence-4417279_1920.jpg'
import image1 from '../../../images/bg-image1.jpg'
import image2 from '../../../images/software-3682509_1920.jpg'

import focusIcon0 from '../../../images/icons/application.svg'
import focusIcon1 from '../../../images/icons/enterprise.svg'
import focusIcon2 from '../../../images/icons/consult.svg'
import focusIcon3 from '../../../images/icons/network.svg'
import focusIcon4 from '../../../images/icons/cloud.svg'
import focusIcon5 from '../../../images/icons/keyboard.svg'

import businessImg0 from '../../../images/businessLine/businessDev.jpg'
import businessImg1 from '../../../images/businessLine/businessTest.jpg'
import businessImg2 from '../../../images/businessLine/businessSecurity.jpg'
import businessImg3 from '../../../images/businessLine/businessArch.jpg'
import businessImg4 from '../../../images/businessLine/businessTraining.jpg'
import businessImg5 from '../../../images/businessLine/businessCloud.jpg'

import workIcon0 from '../../../images/work/listen.svg'
import workIcon1 from '../../../images/work/ideate.svg'
import workIcon2 from '../../../images/work/recommend.svg'
import workIcon3 from '../../../images/work/implement.svg'
import workIcon4 from '../../../images/work/deliver.svg'
import workIcon5 from '../../../images/work/adopt.svg'

import clientLogo0 from '../../../images/projects/identiko.png'
import clientLogo1 from '../../../images/projects/wapic.png'
import clientLogo2 from '../../../images/projects/makeMe.png'
import clientLogo3 from '../../../images/projects/omniSwift.png'
import clientLogo4 from '../../../images/projects/clohea.png'

import SamuelObodeh from '../../../images/partners/samuel-obodeh.jpg'
import DemolaMalomo from '../../../images/partners/demola-malomo.jpg'
// import JosephSaheeb from '../../../images/partners/joseph-saheeb.jpg'
import GerardTimi from '../../../images/partners/gerard-timi.jpg'

export const Data = [
    {
    id: 0,
    heroHeader: 'Application Development',
    heroText: 'SAED offers a next-generational Enterprise Application Development that enables you achieve your goals and places you ahead of the competition.',
    image: image0
    },
    {
    id: 1,
    heroHeader: 'Enterprise Solutions',
    heroText: 'SAED aims to improve client profit, proficiency and productivity by helping enterprises build sophisticated, robust, scalable and extensible solutions.',
    image: image1
    },
    {
    id: 2,
    heroHeader: 'Consulting & Advisory',
    heroText: 'SAED specializes in IT Consulting and Advisory services. We guide your IT decisions along with your strategic organizational direction and ensure you get the best value on your transformation journey.',
    image: image2
    },
]

export const BusinessData = [
    [
        {
        id: 0,
        image: businessImg0,
        businessHeader: 'Software Development',
        businessText: 'SAED is an experienced software development firm providing bespoke software development services...'
        },
        {
        id: 1,
        image: businessImg3,
        businessHeader: 'Solutions Architecture',
        businessText: 'We specialize in process flow design & documentation and End-to-End high-level Solutions Design...'
        },
        {
        id: 2,
        image: businessImg4,
        businessHeader: 'IT Training',
        businessText: 'We offer professional trainings on cutting edge technologies, designed and delivered by the industry experts...'
        },
    ],
    [
        {
        id: 3,
        image: businessImg5,
        businessHeader: 'Cloud Services',
        businessText: 'We provide ITaaS(IT as a Service) which also widely includes our cloud services...'
        },
        {
        id: 4,
        image: businessImg1,
        businessHeader: 'Software Testing',
        businessText: 'We offer a wide range of software testing services which are listed below Services include:...'
        },
        {
        id: 5,
        image: businessImg2,
        businessHeader: 'Application Security',
        businessText: 'We ensure all loopholes are explored from an application security perspective giving you a 99.9% comfort level...'
        },
    ]
]

export const BusinessDataMobile = [
    {
    id: 0,
    image: businessImg0,
    businessHeader: 'Software Development',
    businessText: 'SAED is an experienced software development firm providing bespoke software development services...'
    },
    {
    id: 1,
    image: businessImg3,
    businessHeader: 'Solutions Architecture',
    businessText: 'We specialize in process flow design & documentation and End-to-End high-level Solutions Design...'
    },
    {
    id: 2,
    image: businessImg4,
    businessHeader: 'IT Training',
    businessText: 'We offer professional trainings on cutting edge technologies, designed and delivered by the industry experts...'
    },
    {
    id: 3,
    image: businessImg5,
    businessHeader: 'Cloud Services',
    businessText: 'We provide ITaaS(IT as a Service) which also widely includes our cloud services...'
    },
    {
    id: 4,
    image: businessImg1,
    businessHeader: 'Software Testing',
    businessText: 'We offer a wide range of software testing services which are listed below Services include:...'
    },
    {
    id: 5,
    image: businessImg2,
    businessHeader: 'Application Security',
    businessText: 'We ensure all loopholes are explored from an application security perspective giving you a 99.9% comfort level...'
    },
]

export const FocusData = [
    {
    id: 0,
    focusHeader: 'Application Development',
    focusText: 'SAED offers a next-generational Enterprise Application Development that enables you achieve your goals and places you ahead of the competition.',
    icon: focusIcon0
    },
    {
    id: 1,
    focusHeader: 'Enterprise Solutions',
    focusText: 'we aim to improve client profit, proficiency and productivity by helping enterprises build sophisticated, robust, scalable and extensible solutions.',
    icon: focusIcon1
    },
    {
    id: 2,
    focusHeader: 'Consulting & Advisory',
    focusText: 'SAED specializes in IT Consulting and Advisory services. We guide your IT decisions along with your strategic organizational direction and ensure you get the best value on your transformation journey.',
    icon: focusIcon2
    },
    {
    id: 3,
    focusHeader: 'Networking',
    focusText: 'Helping enterprises build sophisticated networking backbone that are robust, scalable and extensible.',
    icon: focusIcon3
    },
    {
    id: 4,
    focusHeader: 'Managed Services',
    focusText: 'We provide unequaled IT support and managed IT services for businesses in and around Nigeria and West Africa.',
    icon: focusIcon4
    },
    {
    id: 5,
    focusHeader: 'Trainings',
    focusText: 'We believe in making our clients self sufficient therefore, we take pride in impacting knowledge in our clients.',
    icon: focusIcon5
    },        
]

export const WorkData = [
    [
        {
        id: 0,
        icon: workIcon0,
        workHeader: 'Listen',
        workText: 'We pay attention in order to understand your business needs',
        },
        {
        id: 1,
        icon: workIcon1,
        workHeader: 'Ideate',
        workText: 'We take your requirements, measuring and analyzing with industry standards',
        },
        {
        id: 2,
        icon: workIcon2,
        workHeader: 'Recommend',
        workText: 'We give you several options with pros & cons based on the analysis of your needs & current standards then we',
        },
    ],  
    [
        {
        id: 3,
        icon: workIcon3,
        workHeader: 'Implement',
        workText: 'Carry out implementation using modern techniques and methodologies',
        },
        {
        id: 4,
        icon: workIcon4,
        workHeader: 'Deliver',
        workText: 'Serve you with world class solution, improving your processes and performance with lower costs',
        },
        {
        id: 5,
        icon: workIcon5,
        workHeader: 'Adopt',
        workText: 'We follow up with clients to ensure our solutions are meeting their needs. We also help train your staff in adopting new technology and processes',
        },
    ]
]

export const WorkDataMobile = [
    {
    id: 0,
    icon: workIcon0,
    workHeader: 'Listen',
    workText: 'We pay attention in order to understand your business needs',
    },
    {
    id: 1,
    icon: workIcon1,
    workHeader: 'Ideate',
    workText: 'We take your requirements, measuring and analyzing with industry standards',
    },
    {
    id: 2,
    icon: workIcon2,
    workHeader: 'Recommend',
    workText: 'We give you several options with pros & cons based on the analysis of your needs & current standards then we',
    },
    {
    id: 3,
    icon: workIcon3,
    workHeader: 'Implement',
    workText: 'Carry out implementation using modern techniques and methodologies',
    },
    {
    id: 4,
    icon: workIcon4,
    workHeader: 'Deliver',
    workText: 'Serve you with world class solution, improving your processes and performance with lower costs',
    },
    {
    id: 5,
    icon: workIcon5,
    workHeader: 'Adopt',
    workText: 'We follow up with clients to ensure our solutions are meeting their needs. We also help train your staff in adopting new technology and processes',
    },
]

export const ProjectData = [
    {
    id: 0,
    image: clientLogo0,
    client: 'Identiko',
    projectOne: 'Data Cleansing Application: An application that dynamically shows flagged record',
    projectTwo: 'Data Cleansing Engine: Microservices dynamically extracting, pulling and manipulating data based on changing client rules.',
    },
    {
    id: 1,
    image: clientLogo1,
    client: 'Wapic',
    projectOne: 'Retail Portal: A portal that enables wapic’s field agent make sales on the go (e.g. car insurance, travel insurance, smart life policy sales)',
    projectTwo: 'Banccasurace: Integration with Access bank systems on policy generation and sales.',
    },
    {
    id: 2,
    image: clientLogo2,
    client: 'Make Me',
    projectOne: ' A mini erp & staff management portal',
    projectTwo: 'Consulting',
    },
    {
    id: 3,
    image: clientLogo3,
    client: 'OmniSwift',
    projectOne: 'Consulting',
    projectTwo: ' Nigerian Army Students Portal',
    },
    {
    id: 4,
    image: clientLogo4,
    client: 'Clohea',
    projectOne: 'A web and mobile application on health records management and telemedicine',
    projectTwo: 'Consulting',
    },
]

export const PartnersData = [
    {
        src: SamuelObodeh,
        name: 'Samuel Obodeh',
        role: 'Technical Partner'
    },
    {
        src: DemolaMalomo,
        name: 'Demola Malomo',
        role: 'Technical Partner'
    },
    // {
    //     src: JosephSaheeb,
    //     name: 'Saheeb Joseph',
    //     role: 'Technical Partner'
    // },
    {
        src: GerardTimi,
        name: 'Gerrard Timi',
        role: 'Technical Partner'
    }
]